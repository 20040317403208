import React from "react";
const style = require("./PaneTitle.less");

interface IProps {
  title: string;
  children?: React.ReactNode;
}

export const PaneTitle = (props: IProps) => {
  return <div className="advert-pane-title">
    <strong className="advert-pane-title-strong">{props.title}</strong>
    <span>{props.children}</span>
  </div>
}