import Layout from "@/layout/Layout";
import React from "react";
import { Tabs } from 'antd';
const style = require('./AdvertPage.less');
import { AdvertPresenter } from "./AdvertPresenter";
import { observer } from "mobx-react";
import { CarouselPane } from "./components/CarouselPane/CarouselPane";
import { ImagePane } from "./components/ImagePane/ImagePane";
import { HorizontalScrollPane } from "./components/HorizontalScrollPane/HorizontalScrollPane";
import { CategoryPane } from "./components/CategoryPane/CategoryPane";
import { toJS } from "mobx";
import autoBind from "auto-bind";

const h5Origin = 'https://mall.yicaihui.net';

interface IState {
  activeKey: string;
}
@observer
export default class AdvertPage extends React.PureComponent<{}, IState> {
  private presenter = new AdvertPresenter();

  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'CAROUSEL',
    };
    autoBind(this);
  }

  componentDidMount() {
    this.presenter.init();
  }

  render() {
    const {activeKey} = this.state;
    return <Layout.Full>
      <div className="advert">
        
        <div className="advert-mall-wrapper">
          <iframe id="mall-iframe" className="mall-home" src={h5Origin + "/#/mall/home_decorate/home_decorate?"}/>
        </div>

        <div style={{height: '100%', overflow: 'hidden'}}>
          <Tabs tabPosition="left" className="advert-tabs" activeKey={activeKey} onChange={key => {
            this.setState({
              activeKey: key
            });
          }}>
            <Tabs.TabPane tab="图片轮播" key="CAROUSEL">
              <CarouselPane presenter={this.presenter} onClickPreview={this.onClickPreview}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="固定图片" key="IMAGE">
              <ImagePane presenter={this.presenter} onClickPreview={this.onClickPreview}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="横向滑动" key="SCROLL">
              <HorizontalScrollPane presenter={this.presenter} onClickPreview={this.onClickPreview}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="分类展示" key="NEW">
              <CategoryPane presenter={this.presenter} onClickPreview={this.onClickPreview}/>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </Layout.Full>
  }

  private onClickPreview(group: string, advertList: any[]) {
    this.presenter.saveAdvertPreviewList(group, advertList).then(() => {
      (document.getElementById('mall-iframe') as any).contentWindow.postMessage({
        eventName: 'advertPreview',
        group
      }, h5Origin);
    })
  }
}