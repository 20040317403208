import { action, makeAutoObservable, runInAction, toJS } from "mobx";
import { AgentApi } from "src/api/AgentApi";
import { Dialog } from "src/components";
import { IAdvert } from "./AdvertInterface";
import { IEditFlag } from "src/interface/interface";
import { v4 as uuidv4 } from 'uuid';

type IPosterAdvert = IAdvert & IEditFlag & {imgUrl?: string};
type ITextAdvert = IAdvert & IEditFlag;

import { message } from "antd";

export class AdvertPresenter {
  public carouselList: IPosterAdvert[] = [];
  public imageList: IPosterAdvert[] = [];
  public horizontalScrollList: ITextAdvert[] = [];
  public categoryList: ITextAdvert[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  @action
  public init() {

    AgentApi.advert.getAdvertList({}).then(res => {
      runInAction(() => {
        this.carouselList = res.filter(i => i.group == 'CAROUSEL');

        this.imageList = res.filter(i => i.group == 'FIXED_THREE');

        this.horizontalScrollList = res.filter(i => i.group == 'HORIZONTAL_SCROLL');

        this.categoryList = res.filter(i => i.group == 'TABS');
      })
    }).catch(e => {
      Dialog.error(e.message);
    })
  }

  @action
  public setCarouselList(carouselList: any[]) {
    this.carouselList = carouselList;
  }

  @action
  public setImageList(imageList: any[]) {
    this.imageList = imageList;
  }

  @action
  public setHorizontalScrollList(horizontalScrollList: any[]) {
    this.horizontalScrollList = horizontalScrollList;
  }

  @action
  public setCategoryList(categoryList: any[]) {
    this.categoryList = categoryList;
  }

  @action
  public newCarouselItem() {
    const advert = this.getNewImgAdvert('CAROUSEL', this.carouselList.length);
    this.carouselList.push(advert);
  }

  @action
  public newImageItem() {
    const advert0 = this.getNewImgAdvert('FIXED_THREE', 0);
    const advert1 = this.getNewImgAdvert('FIXED_THREE', 1);
    const advert2 = this.getNewImgAdvert('FIXED_THREE', 2);
    this.imageList.push(...[advert0, advert1, advert2]);
  }

  @action
  public newHorizontalScrollItem() {
    const advert = this.getNewTextAdvert('HORIZONTAL_SCROLL', this.horizontalScrollList.length);
    this.horizontalScrollList.push(advert);
  }

  @action
  public newCategoryItem() {
    const advert = this.getNewTextAdvert('TABS', this.categoryList.length);
    this.categoryList.push(advert);
  }

  @action
  public saveAdvertList(advertList: (IAdvert & IEditFlag)[]) {
    const list = toJS(advertList);
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (!item.content) {
        message.error('图片不能为空');
        return;
      }
      if (!item.linkType) {
        message.error('链接类型不能为空');
        return;
      }
      if (!item.linkValues || !Array.isArray(item.linkValues) || item.linkValues.length == 0) {
        message.error({
          CATEGORY: '商品分类',
          ACTIVITY: '促销活动',
          PRODUCT_LIST: '商品',
          PRODUCT_DETAIL: '商品'
        }[item.linkType] +'不能为空');
        return;
      }
      item.index = i;
    }
    AgentApi.advert.editAdvert({
      list: list.map((i, index) => {
        return {
          advertId: i.advertId,
          category: i.category,
          group: i.group,
          content: i.content,
          linkType: i.linkType,
          linkValues: i.linkValues,
          index: index,
          editFlag: i.editFlag,
        };
      })
    }).then(() => {
      showSuccess('保存成功');
      this.init();
    }).catch(e => {
      showError(e.message);
    })
  }

  @action
  public saveAdvertPreviewList(group: string, advertList: (IAdvert & IEditFlag)[]) {
    const list = toJS(advertList);
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (!item.content) {
        message.error('图片不能为空');
        return new Promise(() => {});
      }
      if (!item.linkType) {
        message.error('链接类型不能为空');
        return new Promise(() => {});
      }
      if (!item.linkValues || !Array.isArray(item.linkValues) || item.linkValues.length == 0) {
        message.error({
          CATEGORY: '商品分类',
          ACTIVITY: '促销活动',
          PRODUCT_LIST: '商品',
          PRODUCT_DETAIL: '商品'
        }[item.linkType] +'不能为空');
        return new Promise(() => {});
      }
      item.index = i;
    }
    return AgentApi.advert.saveAdvertPreviewList({
      group,
      list: list.map((i, index) => {
        return {
          advertId: i.advertId,
          category: i.category,
          group: i.group,
          content: i.content,
          linkType: i.linkType,
          linkValues: i.linkValues,
          index: index,
          editFlag: i.editFlag,
        };
      })
    }).then(() => {}).catch(e => {
      showError(e.message);
    })
  }

  private getNewImgAdvert(group: string, index: number) {
    return this.getNewAdvert('POSTER', group, index);
  }

  private getNewTextAdvert(group: string, index: number) {
    return this.getNewAdvert('TEXT', group, index);
  }

  private getNewAdvert(category: string, group: string, index: number) {
    const key = uuidv4();
    return {
      content: '',
      linkType: 'CATEGORY',
      linkValues: [],
      editFlag: 'create' as any,
      key: key,
      id: '',
      advertId: '',
      category: category,
      group,
      index
    }
  }
}