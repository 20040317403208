import { Select } from "antd";
import React from "react";
import { AgentApi } from "src/api/AgentApi";
import autoBind from "auto-bind";
const style = require("./ActivitySelect.less");

interface IProps {
  value?: string;
  onChange?(value: string): void;
}

interface IState {
  loading: boolean;
  activityList: any[];
}

export class ActivitySelect extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activityList: []
    };
    autoBind(this);
  }
  componentDidMount(): void {
    AgentApi.activity.getActivityList({
      status: 'ONGOING'
    }).then(res => {
      this.setState({
        loading: false,
        activityList: res
      });
    }).catch(e => {
      showError(e.message);
    })
  }
  render(): React.ReactNode {
    const {loading, activityList} = this.state;
    return <div className="activity-select-wrapper">
      <Select
        className="activity-select"
        loading={loading}
        placeholder="选择..."
        onChange={this.props.onChange}
      >
        {
          activityList.map(i => {
            return <Select.Option value={i.activityId} key={i.id}>{i.activityName}</Select.Option>
          })
        }
      </Select>
    </div>
  }
}