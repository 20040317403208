import React from "react";
require("./HorizontalScrollPane.less");
import { observer } from "mobx-react";
import { PaneTitle } from "../PaneTitle/PaneTitle";
import { Button, Input } from "antd";
import { AdvertPresenter } from "../../AdvertPresenter";
import autoBind from "auto-bind";
import { AdvertEditor } from "../AdvertEditor/AdvertEditor";
import { CreateButton } from "../CreateButton/CreateButton";
import { toJS } from "mobx";

interface IProps {
  presenter: AdvertPresenter;
  onClickPreview(group: string, advertList: any[]): void;
}

@observer
export class HorizontalScrollPane extends React.PureComponent<IProps> {
  private presenter = this.props.presenter;

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render(): React.ReactNode {
    const {horizontalScrollList} = this.presenter;
    return <div className="horizontal-scrollPane-pane">
      <PaneTitle title="商品"></PaneTitle>
      <div className="horizontal-scrollPane-content">
        {
          horizontalScrollList.map(item => {
            return <AdvertEditor mode="text"
              key={item.key || item.id || item.advertId}
              content={item.content}
              type={item.linkType}
              values={item.linkValues}
              disabledTypes={['PRODUCT_DETAIL']}
              onLinkChange={(type, values) => {
                this.presenter.setHorizontalScrollList([{
                  ...item,
                  linkType: type,
                  linkValues: values
                }]);
              }}
              onTextChange={(text) => {
                this.presenter.setHorizontalScrollList([{
                  ...item,
                  content: text
                }]);
              }}
              onClickDelete={() => {
                if (item.editFlag == 'create') {
                  const newList = toJS(horizontalScrollList).filter(i => i.key != item.key);
                  this.presenter.setHorizontalScrollList(newList);
                  return;
                }
                item.editFlag = 'delete';
                const newList = toJS(horizontalScrollList);
                this.presenter.setHorizontalScrollList(newList);
              }}
            />
          })
        }
        {
          horizontalScrollList.length == 0 &&
          <CreateButton onClick={this.onClickNew}/>
        }
      </div>
      <div className="horizontal-scrollPane-bottom">
        <Button type="ghost" style={{marginRight: 20}} onClick={this.onClickPreview}>预览</Button>
        <Button type="primary" onClick={this.onClickSave}>保存</Button>
      </div>
    </div>
  }

  private onClickNew() {
    this.presenter.newHorizontalScrollItem();
  }

  private onClickPreview() {
    this.props.onClickPreview && this.props.onClickPreview('HORIZONTAL_SCROLL', [...this.presenter.horizontalScrollList]);
  }

  private onClickSave() {
    this.presenter.saveAdvertList(this.presenter.horizontalScrollList);
  }
}