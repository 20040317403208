import { Select } from "antd";
import React from "react";
import { CategorySelectTree, ProductAdvanced } from "src/components";
import { ActivitySelect } from "../../ActivitySelect/ActivitySelect";
const style = require("./LinkType.less");
import autoBind from "auto-bind";

interface IProps {
  type?: string;
  values?: string[];
  disabledTypes?: string[];
  onChange?(type: string, values: string[]): void;
}

interface IState {
  type: string;
  values: string[];
}

export class LinkType extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      type: props.type || 'CATEGORY',
      values: props.values || [],
    };
    autoBind(this);
  }
  render(): React.ReactNode {
    const {disabledTypes = []} = this.props;
    const {type} = this.state;

    const label = lintValueDescMap[type];
    return <div className="advert-link-type">
      <div className="advert-link-type-row">
        <div className="advert-link-type-row-label">链接类型</div>
        <div className="advert-link-type-row-select">
          <Select style={{width: '100%'}} value={type} placeholder="选择..." onChange={this.onLinkTypeChange}>
            {
              options.filter(item => !disabledTypes.includes(item.value)).map(item => {
                return <Select.Option value={item.value} key={item.value}>{item.name}</Select.Option>
              })
            }
          </Select>
        </div>
      </div>
      <div className="advert-link-type-row">
        <div className="advert-link-type-row-label">{label}</div>
        <div className="advert-link-type-row-select">
          { this.renderLinkValueSelect() }
        </div>
      </div>
    </div>
  }

  private renderLinkValueSelect() {
    const { type, values } = this.state;
    if (type == 'CATEGORY') {
      const value = values[0];
      return <CategorySelectTree bordered addRoot={false} value={value} onChange={(val) => {
        this.onLinkValueChange([val]);
      }}/>
    }
    if (type == 'ACTIVITY') {
      const value = values[0];
      return <ActivitySelect value={value} onChange={val => {
        this.onLinkValueChange([val]);
      }}/>
    }
    if (type == 'PRODUCT_LIST') {
      return <ProductAdvanced multiple key="PRODUCT_LIST" value={values} onChange={this.onLinkValueChange}/>
    }
    if (type == 'PRODUCT_DETAIL') {
      return <ProductAdvanced key="PRODUCT_DETAIL" value={values} onChange={this.onLinkValueChange}/>
    }
  }

  private onLinkTypeChange(type) {
    this.setState({
      type: type,
      values: [],
    });
    this.props.onChange && this.props.onChange(type, []);
  }

  private onLinkValueChange(values) {
    this.setState({
      values: values
    });
    this.props.onChange && this.props.onChange(this.state.type, values || []);
  }
}

const options = [
  {
    name: '商品分类',
    value: 'CATEGORY'
  },
  {
    name: '促销活动',
    value: 'ACTIVITY'
  },
  {
    name: '商品',
    value: 'PRODUCT_LIST'
  },
  {
    name: '商品详情',
    value: 'PRODUCT_DETAIL'
  }
];

const lintValueDescMap = {
  CATEGORY: '商品分类',
  ACTIVITY: '促销活动',
  PRODUCT_LIST: '商品',
  PRODUCT_DETAIL: '商品'
};