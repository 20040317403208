import React from "react";
import { PaneTitle } from "../PaneTitle/PaneTitle";
import { observer } from "mobx-react";
const style = require("./CarouselPane.less");
import { IAdvert } from "../../AdvertInterface";
import { Button } from "antd";
import autoBind from "auto-bind";
import { AdvertPresenter } from "../../AdvertPresenter";
import { CreateButton } from "../CreateButton/CreateButton";
import { AdvertEditor } from "../AdvertEditor/AdvertEditor";
import { toJS } from "mobx";

interface IProps {
  presenter: AdvertPresenter; 
  onClickPreview(group: string, advertList: any[]): void;
  onClickDelete?(data: IAdvert): void;
  onClickEdit?(data: IAdvert): void;
}

export const CarouselPane = observer(class CarouselPaneComponent extends React.PureComponent<IProps, {}> {
  private presenter = this.props.presenter;

  constructor(props: IProps) {
    super(props);
    autoBind(this);
  }

  render(): React.ReactNode {
    const {carouselList} = this.props.presenter;
    return <div className="carousel-wrapper">
      <PaneTitle title="图片广告"></PaneTitle>
      <div className="carousel-tip">图片尺寸建议1125px * 450px，图片大小小于150kb</div>
      <div className="carousel-content">
        {
          carouselList.filter(item => item.editFlag !== 'delete').map(item => {
            return <AdvertEditor key={item.key || item.id || item.advertId}
              mode="img"
              content={item.imgUrl || item.content}
              type={item.linkType}
              values={item.linkValues}
              onClickDelete={() => {
                if (item.editFlag == 'create') {
                  const newList = toJS(carouselList).filter(i => i.key != item.key);
                  this.presenter.setCarouselList(newList);
                  return;
                }
                item.editFlag = 'delete';
                const newList = toJS(carouselList);
                this.presenter.setCarouselList(newList);
              }}
              onImgChange={(imgId, imgUrl) => {
                item.editFlag = 'update';
                item.content = imgId;
                item.imgUrl = imgUrl;
                const carouselList = toJS(this.props.presenter.carouselList);
                this.presenter.setCarouselList(carouselList);
              }}
              onLinkChange={(type, values) => {
                item.editFlag = 'update';
                item.linkType = type;
                item.linkValues = values;
                const carouselList = toJS(this.props.presenter.carouselList);
                this.presenter.setCarouselList(carouselList);
              }}
            />
          })
        }
        <CreateButton onClick={this.onClickNew}/>
      </div>
      <div className="carousel-bottom">
        <Button type="ghost" style={{marginRight: 20}} onClick={this.onClickPreview}>预览</Button>
        <Button type="primary" onClick={this.onClickSave}>保存</Button>
      </div>
    </div>
  }

  private onClickNew() {
    this.presenter.newCarouselItem();
  }

  private onClickPreview() {
    this.props.onClickPreview && this.props.onClickPreview('CAROUSEL', [...this.presenter.carouselList]);
  }

  private onClickSave() {
    this.presenter.saveAdvertList(this.presenter.carouselList);
  }
})