import React from "react";
import { PaneTitle } from "../PaneTitle/PaneTitle";
import { Button } from "antd";
import { CreateButton } from "../CreateButton/CreateButton";
import { AdvertEditor } from "../AdvertEditor/AdvertEditor";
import { AdvertPresenter } from "../../AdvertPresenter";
import autoBind from "auto-bind";
import { observer } from "mobx-react";
import { toJS } from "mobx";
const style = require("./CategoryPane.less");

interface IProps {
  onClickPreview(group: string, advertList: any[]): void;
  presenter: AdvertPresenter;
}

export const CategoryPane = observer(class CategoryPane extends React.PureComponent<IProps> {
  private presenter = this.props.presenter;

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render(): React.ReactNode {
    const {categoryList} = this.presenter;
    return <div className="category-pane">
      <PaneTitle title="商品"></PaneTitle>
      <div className="category-pane-content">
        {
          categoryList.map(item => {
            return <AdvertEditor
              key={item.key || item.id || item.advertId}
              disabledTypes={['ACTIVITY', 'PRODUCT_DETAIL']}
              mode="text"
              content={item.content}
              type={item.linkType}
              values={item.linkValues}
              onTextChange={(text) => {
                if (item.advertId) {
                  item.editFlag = 'update';
                }
                item.content = text;
                const carouselList = toJS(this.props.presenter.categoryList);
                this.presenter.setCategoryList(carouselList);
              }}
              onClickDelete={() => {
                if (item.editFlag == 'create') {
                  const newList = toJS(categoryList).filter(i => i.key != item.key);
                  this.presenter.setCategoryList(newList);
                  return;
                }
                item.editFlag = 'delete';
                const newList = toJS(categoryList);
                this.presenter.setCategoryList(newList);
              }}
              onLinkChange={(type, values) => {
                if (item.advertId) {
                  item.editFlag = 'update';
                }
                item.linkType = type;
                item.linkValues = values;
                const newList = toJS(categoryList);
                this.presenter.setCategoryList(newList);
              }}
            />
          })
        }
        <CreateButton onClick={this.onClickNew}/>
      </div>
      <div className="category-pane-bottom">
        <Button type="ghost" style={{marginRight: 20}} onClick={this.onClickPreview}>预览</Button>
        <Button type="primary" onClick={this.onClickSave}>保存</Button>
      </div>
    </div>
  }

  private onClickNew() {
    this.presenter.newCategoryItem();
  }

  private onClickPreview() {
    this.props.onClickPreview && this.props.onClickPreview('TABS', [...this.presenter.categoryList]);
  }

  private onClickSave() {
    this.presenter.saveAdvertList(this.presenter.categoryList);
  }
})