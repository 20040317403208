import { observer } from "mobx-react";
import React from "react";
import { LinkType } from "./LinkType/LinkType";
import { CloseOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
const style = require("./AdvertEditor.less");
import { v4 as uuidv4 } from 'uuid';
import { autobind } from "src/utils/autobind";
import { Input, Spin, message } from "antd";
import { AgentApi } from "src/api/AgentApi";

interface IProps {
  mode: 'img' | 'text';
  content?: string;
  type?: string;
  values?: string[];
  disabledDelete?: boolean;
  disabledTypes?: ('CATEGORY' | 'ACTIVITY' | 'PRODUCT_DETAIL' | 'PRODUCT_LIST')[];
  onClickDelete?(): void;
  onImgChange?(imgId: string, imageUrl: string): void;
  onTextChange?(text: string): void;
  onLinkChange?(type: string, values: string[]): void;
}

interface IState {
  uploading: boolean;
}

export const AdvertEditor = observer(class AdvertEditor extends React.PureComponent<IProps, IState> {
  private inputId = uuidv4()
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
    autobind(this);
  }
  render(): React.ReactNode {
    const {mode, content, type, values, onClickDelete, disabledTypes, disabledDelete} = this.props;
    const {uploading} = this.state;
    return <div className="advert-editor">
      {
        mode == 'img' &&
        <div className="advert-editor-left">
          {
            content &&
            <>
              <img src={content}/>
              <div className="img-replace">点击替换</div>
            </>
          }
          {
            !content &&
            <div className="advert-editor-img-upload">
              <div><UploadOutlined style={{fontSize: 16}}/></div>
              <div>点击上传</div>
            </div>
          }
          {
            uploading &&
            <div className="advert-editor-img-uploading">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }}/>}/>
            </div>
          }
          {
            !uploading &&
            <label className="advert-editor-img-upload-label" htmlFor={this.inputId}></label>
          }
          <input type="file" id={this.inputId} hidden onChange={this.onInputChange}/>
        </div>
      }
      <div className="advert-editor-right">
        {
          mode == 'text' &&
          <div className="advert-editor-form-row">
            <div className="advert-editor-form-label">标题</div>
            <div className="advert-editor-form-input">
              <Input style={{width: '100%'}} placeholder="填写..." value={content} onChange={(e) => {
                this.props.onTextChange && this.props.onTextChange(e.target.value);
              }}/>
            </div>
          </div>
        }
        <LinkType type={type} values={values} disabledTypes={disabledTypes} onChange={this.props.onLinkChange}/>
      </div>

      {
        !disabledDelete &&
        <div className="advert-editor-delete-btn" onClick={onClickDelete}>
          <CloseOutlined/>
        </div>
      }
    </div>
  }
  
  private async onInputChange(e) {
    const files = e.target.files;
    if(files && files[0]) {
      const file = files[0];
      if(file.size > 1024 * 200) {
        message.error('图片大小不能超过200kb!')
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        const src = e?.target?.result;
        if (!src) return;
        
        const data = new FormData();
        data.append('type', 'advert');
        data.append('image', file);
        this.setState({
          uploading: true,
        });
        AgentApi.upload.image(data).then(res => {
          // res.imageId
          this.props.onImgChange && this.props.onImgChange(res.imageId, res.imageUrl);
          this.setState({
            uploading: false,
          });
        }).catch(e => {
          const input = document.getElementById(this.inputId);
          if (input) {
            input.value = '';
          }
          showError(e.message);
          this.setState({
            uploading: false,
          });
        });
      }
    }
  }
})