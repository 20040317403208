import React from "react";
require("./ImagePane.less");
import { observer } from "mobx-react";
import { PaneTitle } from "../PaneTitle/PaneTitle";
import { Button } from "antd";
import { AdvertEditor } from "../AdvertEditor/AdvertEditor";
import { AdvertPresenter } from "../../AdvertPresenter";
import autoBind from "auto-bind";
import { toJS } from "mobx";
import { CreateButton } from "../CreateButton/CreateButton";
import { CloseOutlined } from "@ant-design/icons";

interface IProps {
  presenter: AdvertPresenter;
  onClickPreview(group: string, advertList: any[]): void;
}

@observer
export class ImagePane extends React.PureComponent<IProps> {
  private presenter = this.props.presenter;

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render(): React.ReactNode {
    const { imageList } = this.presenter;
    return <div className="image-pane">
      <PaneTitle title="图片广告"></PaneTitle>
      <div className="image-tip">图片大小建议小于150kb</div>
      <div className="image-content">
        {
          imageList.length > 0 &&
          <div className="image-content-box">
            {
              imageList.filter(item => item.editFlag != 'delete').map((item, index) => {
                return <div key={item.key || item.id || item.advertId}>
                  {
                    index != 2 &&
                    <div className="image-content-item-title">图片尺寸建议260px * 426px</div>
                  }
                  {
                    index == 2 &&
                    <div className="image-content-item-title">图片尺寸建议400px * 200px</div>
                  }
                  <AdvertEditor mode="img"
                    content={item.imgUrl}
                    type={item.linkType}
                    values={item.linkValues}
                    disabledDelete
                    onImgChange={(imgId, imgUrl) => {
                      if (item.advertId) {
                        item.editFlag = 'update';
                      }
                      item.content = imgId;
                      item.imgUrl = imgUrl;
                      const newList = toJS(imageList);
                      this.presenter.setImageList(newList);
                    }}
                    onLinkChange={(type, values) => {
                      if (item.advertId) {
                        item.editFlag = 'update';
                      }
                      item.linkType = type;
                      item.linkValues = values;
                      const newList = toJS(imageList);
                      this.presenter.setImageList(newList);
                    }}
                  />
                </div>
              })
            }
            <div className="advert-editor-delete-btn" onClick={() => {
              
            }}>
              <CloseOutlined onClick={this.onClickDelete}/>
            </div>
          </div>
        }
        {
          imageList.length == 0 &&
          <CreateButton onClick={this.onClickNew}/>
        }
      </div>
      <div className="image-bottom">
        <Button type="ghost" style={{marginRight: 20}} onClick={this.onClickPreview}>预览</Button>
        <Button type="primary" onClick={this.onClickSave}>保存</Button>
      </div>
    </div>
  }

  private onClickDelete() {
    const imageList = toJS(this.presenter.imageList);

    const create = imageList.some(i => i.editFlag == 'create');
    if (create) {
      this.presenter.setImageList([]);
    } else {
      const newList = imageList.map(i => {
        return {
          ...i,
          editFlag: 'delete',
        };
      });
      this.presenter.setImageList(newList);
    }
  }

  private onClickNew() {
    this.presenter.newImageItem();
  }

  private onClickPreview() {
    this.props.onClickPreview && this.props.onClickPreview('FIXED_THREE', [...this.presenter.imageList]);
  }

  private onClickSave() {
    this.presenter.saveAdvertList(this.presenter.imageList);
  }
}